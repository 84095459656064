<script setup>
const props = defineProps(["country"]);
const attrs = useAttrs();
const emit = defineEmits(["click"]);
</script>
<template>
  <v-btn @click="emit('click')" class="code-button" :disabled="attrs.readonly">
    <div>+{{ props.country?.code || "0" }}</div>
    <v-svg
      src="/icons/chevron-down-svgrepo-com.svg"
      class="ml-1 mt-1"
      :options="{ width: '20px' }"
    ></v-svg>
  </v-btn>
</template>

<style scoped>
.code-button {
  min-width: 70px;
  /* display: flex; */
  /* align-items: flex-end; */
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--dark-color6);
}
</style>
